import classNames from "clsx";
import ReactMarkdown from "react-markdown";

import { A, OL, UL } from "../MarkdownElements";

type Props = {
  content: string;
  containerClasses?: string;
};

export default function TextBlock({ content, containerClasses }: Props) {
  return (
    <div
      className={classNames(
        "text-gray-900 prose first:prose-h1:mt-0 prose-h1:mt-8 prose-h1:mb-4 lg:prose-h1:mt-14 lg:prose-h1:mb-5 prose-h1:text-2xl sm:prose-h1:text-3xl prose-h1:font-bold prose-h1:text-gray-900 prose-p:text-base prose-p:font-normal prose-p:text-gray-900 prose-ul:text-gray-900 prose-li:text-base prose-li:font-normal prose-li:text-gray-900",
        containerClasses,
      )}
    >
      <ReactMarkdown
        disallowedElements={["script", "style", "input"]}
        components={{
          ul: UL,
          ol: OL,
          a: A,
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
}
